import { createBrowserRouter,Route } from "react-router-dom";
import Home from "../pages/Home";
import Buy from "../pages/Buy";
import BuyManual from "../pages/BuyManual";
import Doc from "../pages/Doc";
import NewDoc from "../pages/NewDoc";
import React from "react";
import Subscribe from "../pages/Subscribe";
import CoinPal from "../pages/CoinPal";

export default createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/buy",
    element: <Buy />,
  },
  {
    path: "/buy-manual",
    element: <BuyManual />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/coinpal",
    element: <CoinPal />,
  },
  {
    path: "/agreement",
    element: <Doc type="agreement" />,
  },
  {
    path: "/paid",
    element: <Doc type="paid" />,
  },
  {
    path: "/renew",
    element: <Doc type="renew" />,
  },
  {
    path: "/regulate",
    element: <Doc type="regulate" />,
  },
  {
    path: "/privacy",
    element: <Doc type="privacy" />,
  },
  {
    path: "/feedback",
    element: <Doc type="feedback" />,
  },
]);
