import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./LanguageSelector.scss";

interface IProps {
  h5: boolean;
}

const LanguageSelector: React.FC<IProps> = (props) => {
  const { h5 } = props;
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'zh_hant', name: '繁體中文' },
    { code: 'zh_hans', name: '简体中文' },
    { code: 'ja', name: '日本語' },
    { code: 'ko', name: '한국인' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fr', name: 'Français' },
    { code: 'it', name: 'Italiano' },
  ];

  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [langName, setlangName] = useState<string>('English');

  const handleLanguageChange = (code: string, name: string) => {
    i18n.changeLanguage(code);
    setIsOpen(false);
    setlangName(name);
  };

  return (
    <div className={h5 ? "language-dropdown-h5": "language-dropdown"}>
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        <span>{langName}</span>
        <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {languages.map(lang => (
            <div
              key={lang.code}
              className="dropdown-item"
              onClick={() => handleLanguageChange(lang.code, lang.name)}
            >
              {lang.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;