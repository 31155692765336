import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/base.scss";
import { RouterProvider } from "react-router-dom";
import router from "./service/router";
import './i18n';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);



root.render(<RouterProvider router={router} />);
