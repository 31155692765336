export default {
  common: {
    home_subtitle: 'Draw Your AI Girl Images',
    home_desc: 'From Text to AI Beauty<br>Swap Face with AI<br>Edit Any Clothing',
    purchase_desc: 'Purchase Credits With Better Price',
    purchase_btn: 'Purchase',
    faq_title: 'Frequently Asked Questions',
    contact_us: 'Contact us:',
    ending_try_desc: 'Try out AI Lady, the best tool for generating AI girl art',
    try_btn: 'Try It Now!',
  },

  download_btn: {
    android: 'Android App',
    ios: 'iOS App',
    web: 'Web Version',
  },

  banner: {
    title_1: 'From Text to AI Girl Image',
    desc_1: 'Ever dreamed of turning your wildest imagination into reality? Now you can with our new AI image generator. Simply describe your ideal girl, and watch as our AI brings her to life. ',

    title_2: 'Anime Generator',
    desc_2: 'Step into the world of anime with our powerful image generator. Create unique and captivating anime characters with just a few clicks. Customize their appearance, style, and even their emotions.',

    title_3: 'Create High-quality AI Ahegao Arts',
    desc_3: 'Just input the description of the girl or the anime (manga) character you want, select the corresponding Ahegao style, and click to generate it. ',

    title_4: 'Swap Face with AI',
    desc_4: 'Transform your favourite girl into any character you can imagine with our AI-powered face swap feature. Simply upload your photo and describe the character you want to embody, and our AI will generate a personalized image that perfectly captures your desired transformation.',

    title_5: 'Reference Pose',
    desc_5: 'Want to create custom images based on specific poses or styles? Our AI generator allows you to upload a reference image and input a detailed description. Our algorithm will then generate images that capture the essence of the pose while incorporating your desired elements.',

    title_6: 'AI Clothes Changer',
    desc_6: 'Looking for a way to magically change your or your favourite girl character\'s clothes in a photo? Upload a picture, and let our AI change clothes for you as needed immediately.',

    title_7: 'AI Eraser',
    desc_7: 'The best free tool to clean up your pictures and remove any object, person, or watermark in seconds. Make sure you are 18+ if you want to try the erasing clothing feature.',
  },

  faq: {
    q1: 'How do I get a discounted price for my purchase?',
    a1: 'You can purchase at a discounted price on our official website at aipeak.ai',

    q2: 'How can I get credits for free?',
    a2: 'Refer Friends by sharing your link in the settings. When a friend signs up with your link, both of you will get 10 credits!',

    q3: 'How can I make the AI adhere more closely to my requirements?',
    a3: 'To ensure the AI closely follows your requirements in app, it\'s crucial to fine-tune several aspects: <br><br>- the prompt, <br>- chosen style, <br>- prompt relevance settings, <br>- and the image quality settings. <br><br>At times, prompts and styles might clash, but you can emphasize your style preference by selecting \'auto\' or applying parentheses to your prompt for added weight.',

    q4: 'Why doesn\'t the image quality meet my expectations?',
    a4: 'The quality of the generated image might not always align with your expectations due to several factors. <br><br>Adjusting the prompt, the Image Quality, or The prompt relevance parameters could enhance the result. The chosen style could also influence the outcome. We recommend experimenting with these settings for improved results.',

    q5: 'Why is there a limit on the number of free uses?',
    a5: 'The operation and maintenance of our app servers are costly, necessary for delivering high-quality services. Subscribing or purchasing plans support us in covering these expenses and enable us to offer enhanced services to Pro users. <br><br>We appreciate your understanding and support.',

    q6: 'Why do image generations sometimes fail? What happens if an image generation fails?',
    a6: 'Image generation failures often occur for non-members when the created images, upon review, are found to breach certain restrictions, such as promoting hate, discrimination, or containing explicit content. <br><br>Should image generation fail, you can opt to regenerate the image. Rest assured, credits used for a failed attempt are returned to you and not deducted.',

    q7: 'What kind of content is banned?',
    a7: 'We respect people\'s taste and fetishes. However, there are certain types of content that are not acceptable on this site and will be removed immediately to ensure that the site complies with hosting requirements.<br>- Child pornography<br>- Sexualized depictions of minors<br>- Heavy gore<br>- Bestiality<br>- Sexual violence<br>Please help us report the content if you see it.',
  },
}