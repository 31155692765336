import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import aipeakEn from './aipeak/en';
import aipeakDe from './aipeak/de';
import aipeakFr from './aipeak/fr';
import aipeakIt from './aipeak/it';
import aipeakJa from './aipeak/ja';
import aipeakKo from './aipeak/ko';
import aipeakZhHans from './aipeak/zh-hans';
import aipeakZhHant from './aipeak/zh-hant';

import nsfwEn from './nsfw/en';
import nsfwDe from './nsfw/de';
import nsfwFr from './nsfw/fr';
import nsfwIt from './nsfw/it';
import nsfwJa from './nsfw/ja';
import nsfwKo from './nsfw/ko';
import nsfwZhHans from './nsfw/zh-hans';
import nsfwZhHant from './nsfw/zh-hant';

const isNsfw = window.location.hostname.includes('nsfw');

const aipeakResources = {
  en: {
    translation: aipeakEn
  },
  de: {
    translation: aipeakDe
  },
  fr: {
    translation: aipeakFr
  },
  it: {
    translation: aipeakIt
  },
  ja: {
    translation: aipeakJa
  },
  ko: {
    translation: aipeakKo
  },
  zh_hans: {
    translation: aipeakZhHans
  },
  zh_hant: {
    translation: aipeakZhHant
  },
};

const nsfwResources = {
  en: {
    translation: nsfwEn
  },
  de: {
    translation: nsfwDe
  },
  fr: {
    translation: nsfwFr
  },
  it: {
    translation: nsfwIt
  },
  ja: {
    translation: nsfwJa
  },
  ko: {
    translation: nsfwKo
  },
  zh_hans: {
    translation: nsfwZhHans
  },
  zh_hant: {
    translation: nsfwZhHant
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    // resources: isNsfw ? nsfwResources : aipeakResources
    resources: aipeakResources // 不再使用nsfw
  });

export default i18n;