import React from "react";
import { isMobile } from "../../service/util";
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <div className="p-30 text-center text-white flex items-center justify-center flex-wrap font-[20]">
      <div className="whitespace-nowrap">
        {t('common.contact_us')} <a href="mailto:feedback@aipeak.ai" className="text-white">feedback@aipeak.ai</a>
        <br /><br />
        {/* AiPeak Technology Limited © 2023 */}
      </div>
    </div>
  );
};