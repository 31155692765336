import React, { useState, useRef, useEffect } from "react";
import "./home_h5.scss";

const userId = new URLSearchParams(window.location.search).get(
    "id"
) as string;

const CoinPal: React.FC = () => {
    const products = [
        { id: 84, title: "120 Credits", subtitle: "$0.01", benefitsTitle: "Buy Credits,<br>Get Free Bonus", benefitsBody: "Unlock All Features<br>1 month membership for free" },
    ];

    const [selectedProduct, setSelectedProduct] = useState<number>(products.length > 0 ? products[0].id : -1);
    const selectedProductRef = useRef<number>(-1);

    const selectProduct = (productId: number) => {
        setSelectedProduct(productId);
    };

    useEffect(() => {
        selectedProductRef.current = selectedProduct;
    }, [selectedProduct]);

    const getBenefits = (productId: number) => {
        const product = products.find(p => p.id === productId);
        return {
            benefitsTitle: product ? product.benefitsTitle : '',
            benefitsBody: product ? product.benefitsBody : ''
        };
    };

    const { benefitsTitle, benefitsBody } = getBenefits(selectedProduct);

    const createOrder = async () => {
        try {
            const response = await fetch("/api/account/coinpal/order/create", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    mdseId: selectedProductRef.current,
                    userId: userId,
                }),
            });

            const resData = await response.json();

            if (resData.nextStepContent) {
                window.location.href = resData.nextStepContent;
            } else {
                throw new Error(resData.errorMessage);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return (
        <>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center buy-bg">
                <div className="paypal-popup p-8 rounded-lg relative">
                    {/* <div className="flex justify-between items-center">
            <h2 className="paypal-popup-title">Buy Credits</h2>
            <button className="close-button absolute top-4 right-4" onClick={toggleModal}>×</button>
          </div> */}
                    <div className="flex justify-start items-start">
                        <p className="benefits-title" dangerouslySetInnerHTML={{ __html: benefitsTitle }} />
                    </div>
                    <div className="flex justify-start items-start mb-4">
                        <p className="benefits-body" dangerouslySetInnerHTML={{ __html: benefitsBody }} />
                    </div>
                    {products.map((product, index) => (
                        <div
                            key={product.id}
                            className={`product-button p-4 mb-4 cursor-pointer ${selectedProduct === product.id ? 'selected' : ''}`}
                            onClick={() => selectProduct(product.id)}
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            <h3 className="text-lg font-bold">{product.title}</h3>
                            <h3 className="text-lg text-right">{product.subtitle}</h3>
                        </div>
                    ))}
                    <div className="mt-30 flex">
                        <div onClick={createOrder} className="coinpal-button mr-2 ml-2 cursor-pointer">Buy</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CoinPal;
