import React, { useEffect } from "react";
import "./DownloadBtn.scss";
import { homeAppCheckVersion } from "../../service/api";
import { useTranslation } from 'react-i18next';

interface IProps {
  type: "android" | "ios" | "webapp";
  h5: boolean;
}

const DownloadBtn: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { type, h5 } = props;
  let downloadUrl: string = "";
  var icon;
  // let default_download: string;
  if (type === "android") {
    icon = require("../../assets/images/home/android.webp");
    downloadUrl = "https://aipeak.ai/picx-v16.apk";
  } else if (type === "ios") {
    icon = require("../../assets/images/home/iphone.webp");
    downloadUrl = "https://apps.apple.com/us/app/ipicx/id6476877278";
  } else {
    icon = require("../../assets/images/home/web.webp");
    downloadUrl = "https://p.aipeak.ai/";
  }

  // useEffect(() => {
  //   homeAppCheckVersion({
  //     appKey: type === "android" ? "com.aipeak.picx" : "AI_BEAUTY_A_I",
  //   }).then((res) => {
  //     if (res.code === 0) {
  //       downloadUrl = res.data.downloadUrl ? res.data.downloadUrl : default_download;
  //     } else {
  //       downloadUrl = default_download;
  //     }
  //   });
  // }, []);
  const downloadHandler = () => {
    window.location.href = downloadUrl;
  };
  return (
    <div
      onClick={downloadHandler}
      className={h5 ? "download-button-h5 mr-2 ml-2 cursor-pointer" : "download-button mr-15 ml-10 cursor-pointer"}
    >
      <img className="mr-2" width={h5 ? "17" : "20"} src={icon} />
      {type === "android" ? t('download_btn.android') : type === "ios" ? t('download_btn.ios') : t('download_btn.web')}
    </div>
  );
};

export default DownloadBtn;
