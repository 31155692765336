import React, { useState, useRef, useEffect } from "react";
import "./home_h5.scss";
import { PayPalScriptProvider, PayPalButtons, PayPalButtonsComponentProps, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';

const userId = new URLSearchParams(window.location.search).get(
  "id"
) as string;

const Subscribe: React.FC = () => {
  const initialOptions: ReactPayPalScriptOptions = {
    clientId: "AZjfKeey1JWTmPiOaGZmQuZqNCZxiFCYMI8kZOljm8a5zm7F7LnNL3hUnjN0VMqIPbhI4fCICl53G_y3",
    vault: true,
    intent: "subscription",
  };

  const products = [
    // 测试环境
    // { id: 5007005, title: "Weekly VIP", subtitle: "$5.99", plan_id: "P-6J450934D4295823GM262SDA",
    //   benefitsTitle: "Subscribe for VIP", benefitsBody: "Unlock All Features<br>Weekly membership" },
    // { id: 5365029, title: "Yearly VIP", subtitle: "$29.99", plan_id: "P-92351432UE1203146M3EDEIY",
    //   benefitsTitle: "Subscribe for VIP", benefitsBody: "Unlock All Features<br>Yearly membership" },
    // 线上环境
    { id: 5007005, title: "Weekly VIP", subtitle: "$5.99", plan_id: "P-0VA76930KU679550WM3IE5DQ",
      benefitsTitle: "Subscribe for VIP", benefitsBody: "Unlock All Features<br>Weekly membership" },
    { id: 5365029, title: "Yearly VIP", subtitle: "$29.99", plan_id: "P-0N257431X0818314CM3IE54A",
      benefitsTitle: "Subscribe for VIP", benefitsBody: "Unlock All Features<br>Yearly membership" },
  ];

  const [selectedProduct, setSelectedProduct] = useState<number>(products.length > 0 ? products[0].id : -1);
  const selectedProductRef = useRef<number>(-1);
  const [selectedPlanId, setSelectedPlanId] = useState<string>(products.length > 0 ? products[0].plan_id : '');
  const selectedPlanIdRef = useRef<string>('');

  const selectProduct = (productId: number, planId: string) => {
    setSelectedProduct(productId);
    setSelectedPlanId(planId);
  };

  useEffect(() => {
    selectedProductRef.current = selectedProduct;
  }, [selectedProduct]);
  useEffect(() => {
    selectedPlanIdRef.current = selectedPlanId;
  }, [selectedPlanId]);

  const getBenefits = (productId: number) => {
    const product = products.find(p => p.id === productId);
    return {
      benefitsTitle: product ? product.benefitsTitle : '',
      benefitsBody: product ? product.benefitsBody : ''
    };
  };

  const { benefitsTitle, benefitsBody } = getBenefits(selectedProduct);

  const createSubscription: PayPalButtonsComponentProps["createSubscription"] = async (data, actions) => {
    try {
      const response = await fetch("/api/account/paypal/order/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          mdseId: selectedProductRef.current,
          mdseType: 4,
          userId: userId,
          // _sandbox: true, //测试环境
          _sandbox: false, //线上环境
        }),
      });

      const orderData = await response.json();

      if (orderData.outTradeNo) {
        return actions.subscription.create({
          "plan_id": selectedPlanIdRef.current,
          "custom_id": orderData.outTradeNo,
        });
      } else {
        throw new Error(orderData.errorMessage);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const onApproveSubscription: PayPalButtonsComponentProps["onApprove"] = async (data) => {
    alert(`Subscribed successfully! ` + data.subscriptionID);
  };

  // const handlePayPalButtonClick = () => {
  //   if (selectedProductRef.current === -1 || userIdRef.current === '') {
  //     throw new Error('Please select a product and enter your user ID.');
  //   }
  // };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center buy-bg">
        <div className="paypal-popup p-8 rounded-lg relative">
          {/* <div className="flex justify-between items-center">
            <h2 className="paypal-popup-title">Buy Credits</h2>
            <button className="close-button absolute top-4 right-4" onClick={toggleModal}>×</button>
          </div> */}
          <div className="flex justify-start items-start">
            <p className="subscribe-benefits-title" dangerouslySetInnerHTML={{ __html: benefitsTitle }} />
          </div>
          <div className="flex justify-start items-start mb-4">
            <p className="subscribe-benefits-body" dangerouslySetInnerHTML={{ __html: benefitsBody }} />
          </div>
          {products.map((product, index) => (
            <div
              key={product.id}
              className={`product-button p-4 mb-4 cursor-pointer ${selectedProduct === product.id ? 'selected' : ''}`}
              onClick={() => selectProduct(product.id, product.plan_id)}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <h3 className="text-lg font-bold">{product.title}</h3>
              <h3 className="text-lg text-right">{product.subtitle}</h3>
            </div>
          ))}
          <div className="text-center">
            <p className="select-payment-method mx-auto">Select payment method</p>
          </div>
          <div className="mt-4">
            {/* 测试环境 */}
            {/* <PayPalScriptProvider options={{ 'clientId': 'AZjfKeey1JWTmPiOaGZmQuZqNCZxiFCYMI8kZOljm8a5zm7F7LnNL3hUnjN0VMqIPbhI4fCICl53G_y3', 'vault': true }}> */}
              {/* 线上环境 */}
            <PayPalScriptProvider options={{ 'clientId': 'Abm86RUTYo506jNuEObrcj826jWyy_YuzyqfL_S6x2tQY_mHyW8DFnFukKdiYTaX8rm-xNhS_mt0fIOj', 'vault': true }}>
              <PayPalButtons
                createSubscription={createSubscription}
                onApprove={onApproveSubscription}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
